body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "ywsfxt";
  src: url(/static/media/ywsfxt.bb3fa780.eot); /* IE9 */
  src: url(/static/media/ywsfxt.bb3fa780.eot?#iefix) format("embedded-opentype"), 
       url(/static/media/ywsfxt.9fed0d4d.woff) format("woff"), 
       url(/static/media/ywsfxt.65ef3110.ttf) format("truetype"), 
       url(/static/media/ywsfxt.b6bf1006.svg) format("svg"); /* iOS 4.1- */
  font-style: normal;
  font-weight: normal;
}

.hide {
  display: none;
}

header{
  font-family: ywsfxt;
  font-size: 36px;
  font-weight: bold;
  color: #333333;
  line-height: 100px;
  text-align: center;
  padding-top: 20px;
}

.testBody{
  max-width: 500px;
  margin: 0 auto;
}

footer{
  margin-top: 30px;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  color: #aaa;
}

.introduce{
  padding: 10px 20px;
  text-indent: 2em;
  font-size: 16px;
  line-height: 32px;
  color: #2e3b4e;
}

.startButton{
  width: 120px;
  height: 40px;
  color: #ffffff;
  line-height: 40px;
  background: #222222;
  border-radius: 2px;
  display: block;
  text-align: center;
  font-size: 16px;
  margin: 50px auto;
}

.question{
  position: relative;
  padding: 20px;
  font-size: 16px;
  line-height: 32px;
  color: #2e3b4e;
}
.question .questionNo{
  position: absolute;
  left: 20px;
  color: #b2b2b2;
  font-family: ywsfxt;
  font-size: 20px;
  vertical-align: middle;
}
.question .questionNo .no{
  font-size: 50px;
  line-height: 50px;
}
.question .title{
  padding: 60px 0 30px 0;
}
.question .action{
  text-align: center;
}
.question .button{
  width: 120px;
  margin: 0 20px;
  height: 40px;
  color: #2e3b4e;
  line-height: 40px;
  background: #fff;
  border: 2px solid #e9e9e9;
  display: inline-block;
  text-align: center;
}
.question .button:active{
  cursor: pointer;
  background: #fff4e8;
}

.result{
  padding: 20px;
  font-size: 16px;
  line-height: 30px;
  color: #2e3b4e;
}
.result .rs, .result .info{
  padding: 0 20px;
}
#myResultTab{
  width: 300px;
  height: 200px;
  margin: 10px auto;
}
.result .info .title{
  display: block;
  text-align: center;
  font-size: 22px;
  line-height: 60px;
}
.result .info p{
  margin: 0;
  line-height: 30px;
}
.result .info .type{
  font-size: 16px;
}

@media  screen and (max-width:360px) {
  header{
    font-size: 32px;
  }
  .question .button{
    width: 100px;
    margin: 0 10px;
  }
}
